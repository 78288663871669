export function campHandleCNPJ(cnpj: string, print?: "formatted" | "unformatted"): boolean | string {
  cnpj = cnpj.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false; /** Checks size */

  if (/^(\d)\1+$/.test(cnpj)) return false; /** Remove characters */

  if(isNaN(+cnpj)) return false /** Validates if it really is a number */

  /** Runs the calculation */
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  const digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false;
  }

  tamanho += 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--;
    if (pos < 2) {
      pos = 9;
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== parseInt(digitos.charAt(1))) {
    return false;
  }

  // Print
  switch(print) {
    case "unformatted": return cnpj
    case "formatted": return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    default: return true
  }
}

export function campHandleCPF(cpf: string, print?: "formatted" | "unformatted"): boolean | string {
  cpf = cpf.replace(/[^\d]+/g, ''); /** Remove characters */

  if(isNaN(+cpf)) return false /** Validates if it really is a number */

  if (cpf.length !== 11 || /^(.)\1+$/.test(cpf)) return false; /** CPF invalid if it does not have exactly 11 digits or if all digits are equal */

  /** Runs the calculation */
  let sum = 0;
  let remainder: number;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  // Print
  switch(print) {
    case "unformatted": return cpf
    case "formatted": return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    default: return true
  }
}

export function campHandleBrPhoneNumber(phoneNumber: string, print?: "formatted" | "unformatted"): boolean | string {
  phoneNumber = phoneNumber.replace(/[^\d]+/g, '');

  if(isNaN(+phoneNumber))
    return false

  const regex = /^55\d{10,11}$/;
  if(!regex.test(phoneNumber))
    return false

  switch(print) {
    case "unformatted":
      return phoneNumber

    case "formatted":
      return phoneNumber.length < 13 
        ? phoneNumber.replace(/^(\d{2})(\d{2})(\d{4})(\d{4})$/, '+$1 ($2) $3-$4')
        : phoneNumber.replace(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/, '+$1 ($2) $3 $4-$5');

    default:
      return true
  }
}

export function campHandleBrCellPhoneNumber(phoneNumber: string, print?: "formatted" | "unformatted"): boolean | string {
  //Função para validar número de celular.
  phoneNumber = phoneNumber.replace(/[^\d]+/g, '');
  if(isNaN(+phoneNumber)) return false

  const regex = /^55\d{11}$/;
  if(!regex.test(phoneNumber)) {
    return false
  }
  // Print
  switch(print) {
    case "unformatted": return phoneNumber
    case "formatted": return phoneNumber.replace(/^(\d{2})(\d{2})(\d{1})(\d{4})(\d{4})$/, '+$1 ($2) $3 $4-$5');
    default: return true
  }
}

// Email
/**
 * There must be at least an "@" 
 * There must be at least one character between "@" "."
 */
export function campHandleEmailVerification(email: string): boolean {
  const regex = /^[^@\s]+@[^@\s]+\.[a-zA-Z0-9]+$/;
  return regex.test(email);
}

// Checks the empty fields of an object. Example format:
/**
 * object {
 *  att1: First Name Last Name
 *  att2: 51
 *  att3: null
 * }
 */
export function campHandleEmptyFields<T extends { [key: string]: string | number | null }>(obj: T): boolean {
  return Object.values(obj).some(value => value === "" || value === null);
}

// Checks if all attributes are true. Example format:
/**
 * object {
 *  att1: true
 *  att2: true
 *  att3: false
 * }
 */
export function campHandleEveryoneIsTrue(obj: { [key: string]: boolean }): boolean {
  return Object.values(obj).some(value => value === true);
}

export function campHandleFileExtension(file: File, extensions: string[]): boolean {
  const extension = file.name.split(".").pop()?.toLowerCase();

  if (!extension || !extensions.includes(extension)) {
    return false;
  }

  return true;
}

export function campCheckFileSize(file: File, sizeMB: number) {
  return (file.size / 1024**2) <= sizeMB ? true : false
}

export function isCPFValid(cpf) {
  cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos

  // Verifica se o CPF possui 11 dígitos
  if (cpf.length !== 11) {
    return false;
  }

  // Verifica se todos os dígitos são iguais, o que torna o CPF inválido
  if (/^(\d)\1+$/.test(cpf)) {
    return false;
  }

  // Validação dos dígitos verificadores
  let sum = 0;
  let remainder;

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }

  remainder = (sum * 10) % 11;

  if (remainder === 10 || remainder === 11) {
    remainder = 0;
  }

  if (remainder !== parseInt(cpf.substring(10, 11))) {
    return false;
  }

  // CPF válido
  return true;
}

export function formatCPF(cpf) {
  cpf = cpf.replace(/\D/g, ''); // Remove caracteres não numéricos
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Insere ponto após os primeiros 3 dígitos
  cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2'); // Insere ponto após os segundos 3 dígitos
  cpf = cpf.replace(/(\d{3})(\d{2})$/, '$1-$2'); // Insere hífen antes dos últimos 2 dígitos
  return cpf;
}

export function handleCreditCardNumber(cardNumber: string | null, print?: "formatted" | "unformatted"): boolean | string {
  if(cardNumber === null || cardNumber.trim() === ''){
    return false;
  }
  cardNumber = cardNumber.replace(/[^\d]+/g, '');

  if (isNaN(+cardNumber)) return false;

  const regex = /^\d{16}$/;
  if (!regex.test(cardNumber)) {
    return false;
  }

  switch (print) {
    case "unformatted":
      return cardNumber;
    case "formatted":
      return cardNumber.replace(/(\d{4})(?=\d)/g, '$1-');
    default:
      return true;
  }
}


export async function getFileContentType(url: string) {
  try {
      const response = await fetch(url, { method: 'HEAD' })

      if (!response.ok)
          throw new Error(`Failed to get HEAD header for ${url}`)

      const contentType = response.headers.get('content-type')
      
      if (contentType)
          return contentType
      else
          throw new Error(`Content-Type not found for ${url}`)
  } catch (error) {
      console.error('Erro:', (error as Error).message)
      return null
  }
}

export function campHandleLargeName(name: string, maxSize = 30) {
  const arr = name.split(".")
  if(Array.isArray(arr) && arr.length == 2 && arr[0].length > maxSize)
    return `${arr[0].substring(0, maxSize)}... .${arr[1]}`
  else if(name.length > maxSize)
    return `${name.substring(0, maxSize)} ...`
  return name
}

export function campHandleLargeDescription(name: string, maxSize = 30) {
  const arr = name
  if(Array.isArray(arr) && arr.length == 2 && arr[0].length > maxSize)
    return `${arr[0].substring(0, maxSize)}... .${arr[1]}`
  else if(name.length > maxSize)
    return `${name.substring(0, maxSize)} ...`
  return name
}

export function campRemoveLastNonAlphanumericCharacters(chars:string, size = Infinity, last = '') {
  let str = size < Infinity ? chars.substring(0, size) : chars
  str = str.trim()
  while (str.length > 0 && !/[a-zA-Z0-9]/.test(str.charAt(str.length - 1)))
    str = str.slice(0, -1);
  return str + last;
}

export function campLocalStorageGetData<T>(key: string): T | null {
  try {
    const data = localStorage.getItem(key)
    if(data)
      return JSON.parse(data)
    return null
  } catch (error) {
    console.error(error)
    return null
  }
}

export function campLocalStorageSetData(key: string, obj: unknown) {
  try {
    localStorage.setItem(key, JSON.stringify(obj))
  } catch (error) {
    console.error(error)
    localStorage.setItem(key, "")
  }
}

export function handleFormatInMonentaryValue(value: number) {
  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  })
}
